<template>
  <b-col lg="4" class="mb-3">
    <div v-if="feedback.hasValues">
      <b-card body-class="p-0" footer-class="p-0 overflow-hidden">
        <template #header>
          <card-header
            :title="feedback.value"
          />
        </template>
        <div class="text-center">
          <line-chart-card
            :current-filter="currentFilter"
            :search-data="searchData"
            :dataset="dataset"
            :chart-id="feedback.key"
            hour
            day
            week
          ></line-chart-card>
        </div>
        <template #footer>
          <div class="chart__progress-bars">
            <div :style="positiveColWidth">
              <b-progress
                :value="feedback.feedbackValues.countPositive"
                :max="feedback.feedbackValues.countPositive"
                show-value
                variant="success"
                class="progress--is-squared"
              />
            </div>
            <div :style="negativeColWidth">
              <b-progress
                :value="feedback.feedbackValues.countNegative"
                :max="feedback.feedbackValues.countNegative"
                show-value
                variant="danger"
                class="progress--is-squared"
              />
            </div>
          </div>
        </template>
      </b-card>
    </div>
    <div v-else class="d-flex h-100 flex-column">
      <b-card class="h-100" body-class="p-0 position-relative has-chart" footer-class="p-0 overflow-hidden">
        <template #header>
          <card-header
            :title="feedback.value"
          />
        </template>
        <div class="chart-unavailable">
          <span class="chart-unavailable__msg">
            Informazioni non disponibili
          </span>
        </div>
        <template #footer>
          <div class="chart-unavailable__count">
            <span>N/A</span>
          </div>
        </template>
      </b-card>
    </div>
  </b-col>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

const LineChartCard = () => import('@/components/chart/LineChartCard.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'FeedbackSection',
  components: { LineChartCard, CardHeader },
  props: {
    feedback: {
      key: String,
      value: String,
      hasValues: Boolean,
      feedbackValues: {
        countTotal: Number,
        countPositive: Number,
        countNegative: Number,
        sectionPoint: Number,
        averagePoint: Number,
      },
    },
  },
  data() {
    return {
      currentFilter: 'hour',
      loading: false,
      dataset: [],
    };
  },
  mounted() {
    this.searchData(this.currentFilter);
  },
  computed: {
    positiveColWidth() {
      // console.log('pos', (this.feedback.feedbackValues.countPositive / this.feedback.feedbackValues.countTotal) * 100);
      return `width: ${(this.feedback.feedbackValues.countPositive / this.feedback.feedbackValues.countTotal) * 100}%`;
    },
    negativeColWidth() {
      // console.log('neg', this.feedback.feedbackValues.countTotal, this.feedback.feedbackValues.countNegative);
      return `width: ${(this.feedback.feedbackValues.countNegative / this.feedback.feedbackValues.countTotal) * 100}%`;
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
  },
  methods: {
    searchData(filter) {
      if (!this.loading) {
        this.loading = true;
        this.currentFilter = filter;
        const entityType = this.isCompanyProfile ? 'company' : 'person';
        const entityId = this.isCompanyProfile ? this.$store.getters['subject/currentCompany'].companyId : null;
        this.$store.dispatch('score/fetchFeedbackStatistics', {
          entityType, entityId, interval: filter, type: this.feedback.key,
        })
          .then((response) => {
            console.log('fetch response', response);
            this.dataset = response.map((item) => ({ x: new Date(item.date), y: item.totalFeedback }));
            this.loading = false;
          }, (error) => {
            console.log('Error fetch', error);
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>

</style>
